
import signInModal from './signin.vue'
import registerType from './register-type.vue'
export default {
  name: 'Register',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    status: String,
  },
  data() {
    return {
      phoneOrEmail: '',
      isEmail: false,
      account: {
        name: '',
        surname: '',
        email: '',
        phone: ''
      },
      confirm_password: '',
      hidden: {
        password: true,
        confirm_password: true
      },
      loading: false
    }
  },
  watch: {
    phoneOrEmail () {
      const EMAILREG = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (EMAILREG.test(this.phoneOrEmail)) {
        this.isEmail = true
      } else {
        this.isEmail = false
      }
    }
  },
  methods: {
    toContentProvider() {
      this.$router.push({
        path: this.localePath('/content-provider'),
      })
      this.onClose()
    },
    toRegisterType(type) {
      this.$emit('close')
      this.$modal.show(
        registerType,
        { status: 'register', type },
        {
          height: 'auto',
          maxWidth: 500,
          width: window.innerWidth <= 450 ? window.innerWidth - 10 : 450,
          acrollable: true,
        }
      )
    },
    onSubmit() {
      const _user = { ...this.account }
      if (this.phoneOrEmail.includes('@') > 0) {
        _user.email = this.phoneOrEmail
        _user.username = this.phoneOrEmail
        delete _user.phone
      } else if (this.phoneOrEmail.includes('+') > 0) {
        _user.phone = this.phoneOrEmail.substring(1)
        _user.username = this.phoneOrEmail.substring(1)
        delete _user.email
        delete _user.password
      } else {
        _user.phone = this.phoneOrEmail.substring(1)
        _user.username = this.phoneOrEmail.substring(1)
        delete _user.email
        delete _user.password
      }
      this.$axios.post('/auth/local/register', _user).then(async (data) => {
        if (this.isEmail) {
          this.$snotify.info($t('n-login'))
          await this.$auth.loginWith('local', {
            data: { identifier: _user.email, password: _user.password }
          }).then(async (res) => {
            await this.$auth.setToken('local', 'Bearer ' + res.data.jwt)
            // await this.$auth.setRefreshToken('local', res.data.refresh)
            await this.$axios.setHeader('Authorization', 'Bearer ' + res.data.jwt)
            await this.$auth.ctx.app.$axios.setHeader('Authorization', 'Bearer ' + res.data.jwt)
            localStorage.setItem('user_info', JSON.stringify(res.data.user))
            await this.$auth.setUser(res.data.user)
            this.$bridge.$emit('join_chat', {
              username: res.data.user.username,
              user_id: res.data.user.id,
            })
            await this.$snotify.success($t("successfully-login"))
            this.loading = false
            this.onClose()
          }).catch((e) => {
            this.loading = false
            this.$snotify.error(e.response.data.detail)
          })
          return
        }
        if (data.data === false) {
          this.confirmCode({ username: this.user.phone, isOtpSuccess: false, isLogin: null })
          return
        }
        this.confirmCode({ username: data.data.user.phone, isOtpSuccess: null, isLogin: null })
      // eslint-disable-next-line no-console
      }).catch((e) => { console.error(e) })
    },
    openSignIn() {
      this.$emit('close')
      this.$modal.show(
        signInModal,
        { status: 'sign-in' },
        {
          height: 'auto',
          maxWidth: 400,
          width: window.innerWidth <= 350 ? window.innerWidth - 10 : 350,
          acrollable: true,
        }
      )
    },
    onClose(e) {
      this.$emit('close')
      this.$root.$emit('register', e)
    },
  },
}
