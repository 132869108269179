
import signInModal from './signin.vue'
export default {
  name: 'Register',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    status: String,
    type: String,
  },
  data() {
    return {}
  },
  methods: {
    toEnterprise() {
      if (this.type === 'user') {
        this.$router.push({
          path: this.localePath('/user-enterprise'),
        })
      } else
        this.$router.push({
          path: this.localePath('/consultant-enterprise'),
        })
      this.onClose()
    },
    toIndividual() {
      if (this.type === 'user') {
        this.$router.push({
          path: this.localePath('/user-individual'),
        })
      } else
        this.$router.push({
          path: this.localePath('/consultant-individual'),
        })
      this.onClose()
    },
    openSignIn() {
      this.$emit('close')
      this.$modal.show(
        signInModal,
        { status: 'sign-in' },
        {
          height: 'auto',
          maxWidth: 400,
          width: window.innerWidth <= 350 ? window.innerWidth - 10 : 350,
          acrollable: true,
        }
      )
    },
    onClose(e) {
      this.$emit('close')
      this.$root.$emit('sign-in', e)
    },
  },
}
