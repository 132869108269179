
import registeModal from '../modals/register.vue'
export default {
  name: 'Signin',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    status: String,
  },
  data() {
    return {
      auth: {
        identifier: '',
        password: '',
      },
      authError: '',
      tryingToLogIn: false,
      isAuthError: false,
      loading: false,
      isEmail: false,
    }
  },
  watch: {
    'auth.identifier'() {
      const EMAILREG =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (EMAILREG.test(this.auth.identifier)) {
        this.isEmail = true
      } else {
        this.isEmail = false
      }
    },
  },
  methods: {
    async tryToLogIn() {
      if (this.auth.identifier.includes('+') > 0) {
        this.auth.identifier = this.auth.identifier.substring(1)
      }
      this.authError = ''
      this.loading = true
      if (this.isEmail) {
        this.$snotify.info($t('n-login'))
        try {
          await this.$auth.loginWith('local', {
            data: this.auth,
          }).then(async res => {
            await this.$bridge.$emit('join_chat', {
              username: res.data.user.username,
              user_id: res.data.user.id,
            })
            this.loading = false
            await this.$snotify.success('Successfully Logged In')
            this.onClose()
          })
        } catch (e) {
          if (e.response) this.authError = e.response.data.error.message
          this.loading = false
        }
        // await this.$auth
        //   .loginWith('local', {
        //     data: this.auth,
        //   })
        //   .then(async (res) => {
        //     localStorage.setItem('local', 'Bearer ' + res.data.jwt)
        //     await this.$auth.setToken('local', 'Bearer ' + res.data.jwt)
        //     // await this.$auth.setRefreshToken('local', res.data.refresh)
        //     await this.$axios.setHeader('Authorization', 'Bearer ' + res.data.jwt)
        //     await this.$auth.ctx.app.$axios.setHeader('Authorization', 'Bearer ' + res.data.jwt)
        //     await this.$store
        //       .dispatch('getUsers', {
        //         link: '/users/me',
        //         query: {
        //           populate: '*',
        //         },
        //       })
        //       .then((response) => {
        //         this.$auth.setUser(response.data)
        //         localStorage.setItem('user_info', JSON.stringify(response.data))
        //       })
        //     await this.$snotify.success('Successfully Logged In')
        //     this.loading = false
        //     this.$bridge.$emit('join_chat', {
        //       username: res.data.user.username,
        //       user_id: res.data.user.id,
        //     })
        //     this.onClose()
        //   })
        //   .catch((e) => {
        //     this.authError = e.response.data.error.message
        //     this.loading = false
        //   })
      } else {
        this.$axios
          .post('/users-permissions/login-verify-otp', { phone: this.auth.identifier })
          .then(() => {
            this.loading = false
            this.confirmCode({ username: this.auth.identifier, isLogin: true, isOtpSuccess: null })
          })
          .catch((e) => {
            this.openSignUp()
            this.authError = e.response.data.error.message
            this.loading = false
          })
      }
    },
    openRegister() {
      this.$emit('close')
      this.$modal.show(
        registeModal,
        { status: 'register' },
        {
          height: 'auto',
          maxWidth: 700,
          width: window.innerWidth <= 650 ? window.innerWidth - 10 : 650,
          scrollable: true,
        }
      )
    },
    onClose(e) {
      this.$emit('close')
      this.$root.$emit('sign-in', e)
    },
  },
}
